body,
html {
  margin: 0px;
  padding: 0px;
  font-family: 'Merriweather', serif;
  height: 100%;
  
}
// #root, .app, .main_wrapper{
//   height: 100%;
// }

* {
  box-sizing: border-box;
}

.login_wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  height: 100%;
  flex-direction: column;
  width: 100%;
  background: url(../img/back.jpeg);
  background-position: left;

  .login_inner {
    padding: 20px;
    width: 400px;
    border-radius: 5px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      max-width: 170px;
      margin-top: 20px;

      img {
        width: 100%;
      }
    }

    .login_form {
      width: 80%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      .common_field {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        &.button {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;

          button {
            padding: 7px 30px;
            box-shadow: none;
            border: 2px #4d695b solid;
            background: #4d695b;
            color: white;
            cursor: pointer;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      input {
        width: 100%;
        padding: 10px;
        border: none;
        border-bottom: 2px #4d695b solid;
        outline: none;
        font-size: 15px;
      }
    }
  }
}
.slots_booked{
  display: flex;
}
.del_icon, .view_icon{
  font-size: 17px;
  cursor: pointer;
}

.view_icon{
  margin-right: 10px;
}

.c2a{
  padding: 15px;
  background: #c58f36;
  font-size: 20px;
  margin-top: 20px;
  color: white;
  /* border-radius: 5px; */
  position: relative;
  @media screen and (max-width:567px) {
    padding: 10px;
    font-size: 16px;
  }
  a{
    text-decoration: none;
    color: white;
  }
  &::after {
    content: "";
    width: 100%;
    height: 3px;
    background: #83570f;
    position: absolute;
    left: 0;
    bottom: -3px;
}
}

.footer_main{
  display: flex;
  flex-direction: column;
  background: #1a1c39;
  padding: 40px;
  align-items: center;
  p{
    color: white;
    margin-bottom: 0px;
    font-size: 18px;
    .contact{
      padding: 5px 10px;
      background: white;
      color: black;
      margin-right: 15px;
    }
  }
}
.team_pop{
  position: fixed;
  width: 100%;
  background: rgba(0,0,0,.5);
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media screen and (max-width:567px) {
    justify-content: flex-start;
    overflow-y: auto;
  }
  .inner_pop{
    max-width: 70%;
    background: white;
    width: 100%;
    padding: 25px;
    position: relative;
    @media screen and (max-width:567px) {
      max-width: 90%;
    }
    .cross{
      position: absolute;
      right: 25px;
      top: 25px;
      width: 30px !important;
      cursor: pointer;
    }
    p{
      font-size: 15px !important;
    }
  }
}
.main_cases{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width:567px) {
    flex-direction: column;
  }
  .common_case{
    max-width: 32%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    background: white;
    margin-bottom: 20px;
    transition: 0.36s;
    @media screen and (max-width:567px) {
      max-width: 100%;
    }
    &.team{
      position: relative;
      padding-bottom: 40px;
      span{
        &.view_profile{
          position: absolute;
          bottom: 20px;
          
        }
      }
    }
    &:hover{
      box-shadow: 1px 2px 5px #ccc;
      margin-top: -10px;
      cursor: pointer;
    }
    &:nth-child(3n-1){
      margin-left: 1.5%;
      margin-right: 1.5%;
      @media screen and (max-width:567px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    h3{
      width: 100%;
      text-align: center;
      line-height: 1.4;
      &.highlight{
        color: #c58f36;
      }
      
    }
    img{
      height: 70px;
      width: auto !important;
      margin-top: 25px;
      margin-bottom: 20px;
      & + h3{
        min-height: 120px;
        margin-top: 0px;
      }
      &.profile_team{
        height: 200px;  
        & + h3{
          min-height: auto;
        }
      }
    }
    .button{
      margin-top: 20px;
      margin-bottom: 25px;
      a{
        padding: 15px;
        background: #333556;
        color: white;
        text-decoration: none;
        &:hover{
          background-color: #c58f36;
        }
      }
    }
  }
}
.react-tabs{
  width: 100%;
}
.grades{
  background: #ebebeb;
  margin-top: 0px;
  padding: 20px 40px;
  li{
    padding: 10px 0px;
    list-style: square;
  }
}
.main_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .navigation{
    width: 85%;
    display: flex;
    justify-content: flex-end;
    .close_menu{
      display: none;
      height: 30px;
      right: 15px;
      top: 15px;
      z-index: 2;
    }
    &.act{
      .close_menu{
        display: flex;
      }
    }
 
    .menu_mob{
      display: none;
      @media screen and (max-width:567px) {
        display: flex;
        width: 40px;
      }
    }
    ul{
      list-style: none;
      display: flex;
      @media screen and (max-width:567px) {
        display: flex;
        width: 100%;
        position: fixed;
        height: 100%;
        flex-direction: column;
        top: -150%;
        padding: 0px;
        margin: 0px;
        align-items: center;
        justify-content: center;
        left: 0;
        background: white;
        z-index: 1;
        overflow-y: auto;
        transition: 0.5s;
      }
      &.open{
        @media screen and (max-width:567px) {
          top: 0;
        }
      }
      li{
        margin: 0 5px;
        @media screen and (max-width:567px) {
          padding: 15px;
          display: flex;
          justify-content: center;
        }
        a{
          padding: 10px 15px;
          color: black;
          text-decoration: none;
          font-size: 16px;
          border-bottom: 2px transparent solid;
          &:hover{
            border-bottom: 2px #c58f36 solid;
          }
          &.active{
            border-bottom: 2px #c58f36 solid;
          }
          &.c2a{
            padding: 10px;
            color: white;
          }
        }
      }
    }
  }
  .eccm{
    display: flex;
    .main_eccm{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 40px;
      @media screen and (max-width:567px) {
        width: 100%;
        padding: 20px;
       }
      h2{
        font-size: 28px;
        @media screen and (max-width:567px) {
          font-size: 22px;
         }
      }
      p{
        font-size: 17px;
        line-height: 1.8;
        @media screen and (max-width:567px) {
          font-size: 16px;
         }
      }
    }
  }
  .profile{
    display: flex;
    @media screen and (max-width:567px) {
     flex-direction: column-reverse;
    }
    .txt_profile{
      width: 65%;
      display: flex;
      flex-direction: column;
      padding: 40px;
      background: #c58f36;
      @media screen and (max-width:567px) {
        width: 100%;
        padding: 20px;
       }
      h2{
        color: #333556;
        font-size: 30px;
      }
      p{
        color: white;
        font-size: 17px;
        line-height: 1.4;
      }
    }
    .img_profile{
      width: 35%;
      @media screen and (max-width:567px) {
        width: 100%;
       }
      img{
        width: 100%;
        display: flex;
      }
    }
  }
  .vision{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 80px 40px;
    align-items: center;
    justify-content: space-between;
    background: #f0f0f8;
    @media screen and (max-width:567px) {
      padding: 50px 20px;
    }
    &.case_view_detail{
      h4{
        font-size: 18px;
        line-height: 1.7;
        margin-top: 0px;
        margin-bottom: 0px;
        span{
          display: flex;
        }
      }
      h2{
        margin-bottom: 10px;
        color: #333556;
      }
      .common_vis{
        margin-bottom: 30px;
      }
    }
    .common_vis{
      h2{
        font-size: 28px;
        margin-top: 0px;
        @media screen and (max-width:567px) {
          font-size: 22px;
        }
      }
    }
    .vis_main{
      background-color: #333556;
      background-image: url(../img/vision.svg);
      font-size: 25px;
      color: white;
      padding: 40px;
      line-height: 1.5;
      background-position: 50%;
      background-size: cover;
      @media screen and (max-width:567px) {
        font-size: 20px;
        padding: 20px;
      }
    }
    .flag_coun{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span{
        max-width: 80px;
        display: flex;
        margin-right: 20px;
        border: 1px #333556 solid;
        @media screen and (max-width:567px) {
          width: 60px;
          flex-grow: 1;
          flex-shrink: 0;
        }
        img{
          width: 100%;
        }
      }
      h4{
        line-height: 1.5;
        @media screen and (max-width:567px) {
          font-size: 15px;
        }
      }
    }
  }
  .main_inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main_banner{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 100px 40px;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width:567px) {
        flex-direction: column-reverse;
        padding: 50px 20px;
      }
      &.prep{
        padding: 0px;
        img{
          width: 100%;
        }
      }
      &.case_wrapper{
        background: #ebebeb;
        h2{
          margin-bottom: 0px;
          & +p{
            margin-top: 5px;
            margin-bottom: 50px;
          }
        }
      }

      > div{
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width:567px) {
          max-width: 100%;
        }
        &.contact_us{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          max-width: 100%;
          width: 100%;
          flex-direction: row;
          @media screen and (max-width:567px) {
            flex-direction: column;
          }
          h2{
            width: 100%;
          }
          .left_contact{
            max-width: 48%;
            width: 100%;
            @media screen and (max-width:567px) {
              max-width: 100%;
            }
          }
          .right_contact{
            max-width: 48%;
            width: 100%;
            @media screen and (max-width:567px) {
              max-width: 100%;
              margin-top: 30px;
            }
          }
        }
        &.case_view_left{
          max-width: 65%;
          width: 100%;
          @media screen and (max-width:567px) {
            max-width: 100%;
          }
          h3{
            margin-bottom: 0px;
            margin-top: 20px;
            font-size: 40px;
            line-height: 1.4;
            @media screen and (max-width:567px) {
              font-size: 24px;
            }
            &.admit{
              font-size: 25px;
              color: #c58f36;
            }
            &:first-child{
              margin-top: 0px;
            }
          }
          h4{
            margin-bottom: 0px; 
            font-size: 20px;
            line-height: 1.4;
          }
        }
        &.case_view_right{
          max-width: 30%;
          width: 100%;
          @media screen and (max-width:567px) {
            max-width: 70%;
            margin-bottom: 30px;
          }
          img{
            width: 80%;
          }
        }
        &.about_us{
          max-width: 100%;
          width: 100%;
        }
        h2{
          font-size: 38px;
          color: #333556;
          margin-top: 0px;
          margin-bottom: 10px;
          @media screen and (max-width:567px) {
            font-size: 28px;
            margin-top: 20px;
            line-height: 1.5;
          }
        }
        p{
          font-size: 20px;
          line-height: 1.5;
          @media screen and (max-width:567px) {
            font-size: 16px;
          }
        }
        img{
          width: 80%;
          @media screen and (max-width:567px) {
            width: 100%;
          }
        }
      }
      .right_main{
        align-items: center;
      }
    }
    .header_table {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      &.not_tabs{
        margin-bottom: 20px;
      }

      span {
        &.add {
          cursor: pointer;
          background: #4d695b;
          color: white;
          font-weight: 600;
          font-size: 14px;
          padding: 10px 15px;
          margin-left: 15px;
          border-radius: 5px;
          // &.student{
          //   background: #eb6946;
          // } 
          // &.payment{
          //   background: #eb6946;
          // }
          // &.seats{
          //   background-color: #eb6946;
          // }
        }
      }
    }

    .header_main {
      padding: 20px 40px;
      border-bottom: 2px #c58f36 solid;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        &.logo {
          max-width: 100px;
          width: 100%;
          display: flex;

          img {
            width: 100%;
          }
        }

        &.lg {
          text-decoration: underline;
          color: #3e588f;
          cursor: pointer;
        }
      }
    }

    .userData {
      padding: 20px 40px;
      display: flex;
      flex-direction: column;

      .no_found {
        font-size: 30px;
        width: 100%;
        text-align: center;
      }
    }
  }

}
.aboutUS{
  margin-top: 0px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  li{
    padding: 10px 0px;
    font-size: 18px;
    &.abt_img{
      position: absolute;
      top: 20px;
      right: 0px;
      width: 700px;
      list-style: none;
      @media screen and (max-width:567px) {
        position: relative;
        top: initial;
        width: 100%;
      }
      img{
        width: 100%;
      }
    }
  }
}
.seat_pop{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, .3);
  .inner_seat {
    width: 100%;
    display: flex;
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    background: white;
    flex-wrap: wrap;
    position: relative;
    .cross_icon{
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    .seats_box{
      margin: 0 8px;
      padding: 5px 8px;
      background: white;
      cursor: pointer;
      min-width: 36px;
      text-align: center;
      margin-bottom: 8px;
      border: 1px green solid;
      font-size: 13px;
      &.sle{
        background: green;
        color: white;
      }
    }
  }
}
.link_change{
  color: #3e588f;
  text-decoration: underline;
  font-weight: 500;
  margin-left: 20px;
  cursor: pointer;
}
.user_info_form {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, .3);
  &.student_details{
    position: relative;
    background: white;
    justify-content: flex-start;
    .user_form{
      max-width: 100%;
      label{
        width:150px;
      }
    }
  }

  .user_form {
    width: 100%;
    display: flex;
    padding: 20px;
    border-radius: 5px;
    max-width: 900px;
    background: white;
    flex-wrap: wrap;
    justify-content: space-between;



    .common_input {
      display: flex;
      width: 45%;
      margin-bottom: 20px;
      align-items: center;
      .common_label{
        width: 200px;
        display: flex;
        flex-shrink: 0;
        font-size: 14px;
      }

      &.buttons {
        width: 100%;
        justify-content: flex-end;
        margin-top: 20px;

        span {
          cursor: pointer;
          &.can {
            border: 2px #3e588f solid;
            padding: 7px 15px;
            background: white;
            cursor: pointer;
            color: #3e588f;
            margin-right: 30px;
          }

          &.sub {
            border: 2px #3e588f solid;
            padding: 7px 15px;
            background-color: #3e588f;
            color: white;
            cursor: pointer;
          }
        }
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 10px;
        border: none;
        border-bottom: 2px #3e588f solid;
        outline: none;
        font-size: 14px;
      }
    }
  }
  &.payform{
    .common_label{
      width: 120px !important;
    }
  }
}
.heading {
  width: 100%;
  display: flex;
  color: #4d695b;
  font-size: 20px;
  margin-top: 0px;
  &.padding{
    padding: 0 20px;
  }
}
.main_table_box{
  min-height: 450px;
  background: white;
  &.student{
    max-height: inherit;
  }
}
.userTable {
  border-spacing: 0px;
  width: 100%;
  th {
    text-align: left;
    padding: 15px;
    border-bottom: 1px #4d695b solid;
    font-size: 14px;
  }

  td {
    padding: 15px;
    vertical-align: text-top; 
    font-size: 13px;
    border-bottom: 1px #e6e2e2 solid;
    .paynow{
      padding: 5px;
      background: #3e588f;
      color: white;
      cursor: pointer;
      font-size: 12px;
    }
    .paid{
      background: green;
      color: white;
      padding: 5px;
      font-size: 14px;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: #f3f3f3;
      }
    }
  }
}

.common_label.small{
  width: 80px !important;
}
textarea, input, select {
  font-family: inherit;
  font-size: inherit;
}

.loader{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  img{
    height: 70px;
  }
}

/*
 * Note that this is toastr v2.1.3, the "latest" version in url has no more maintenance,
 * please go to https://cdnjs.com/libraries/toastr.js and pick a certain version you want to use,
 * make sure you copy the url from the website since the url may change between versions.
 * */
.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}

/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container>div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container>div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}

#toast-container>div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container>.toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container>.toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container>.toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container>.toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center>div,
#toast-container.toast-bottom-center>div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width>div,
#toast-container.toast-bottom-full-width>div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  #toast-container>div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  #toast-container>div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container>div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }

  #toast-container>div.rtl {
    padding: 15px 50px 15px 15px;
  }
}

.react-tabs__tab-list{
  margin-bottom: 0px;
  border-bottom: 1px solid #c58f36;
  background: transparent;
  @media screen and (max-width:567px) {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    display: flex;
  }
}
.react-tabs__tab{
  padding: 15px;
  @media screen and (max-width:567px) {
    flex-shrink: 0;
  }
}

.react-tabs__tab--selected {
  background: #c58f36;
  border-color: #c58f36;
  color: white;
  border-radius: 5px 5px 0 0;
}

.form-control{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  label{
    font-size: 14px;
    margin-bottom: 10px;
  }
  input, textarea{
    padding: 7px;
    border: 1px #c58f36 solid;
  }
}

.submit_btn{
  width: 150px;
  padding: 8px 0;
  border: none;
  background-color: #333556;
  display: flex;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 0px;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.thankyou{
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3{
    font-size: 30px;
  }
  h5{
    font-size: 20px;
    margin-top: 0px;
  }
}

.actions{
  span{
    padding: 5px;
    color: white;
    background: #333556;
    cursor: pointer;
  }
}


// @media screen and (max-width:567px) {
//   .main_wrapper .main_inner .main_banner{
//     flex-direction: column-reverse;
//   }
//   .main_wrapper .main_inner .main_banner > div{
//     max-width: 100%;
//   }
// }